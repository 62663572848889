import { Col, Container, Row } from "react-bootstrap";
import { Blob_Base_Url, GetCartItem } from "../../utils/static";

const GetItem = (img, cat = "") => ({
  img,
  title: cat,
  cat: `/search?category=${cat}`,
});
export const SmallCategory = () => {
  const items = [
    GetItem("laptop.png", "Comp & Accessories"),
    GetItem("furniture.png", "Furniture"),
    GetItem("fashion.png", "Fashion"),
    GetItem("bath.png", "Bath"),
    GetItem("sports.png", "Sports"),
    GetItem("bed.png", "Bed"),
  ];

  return (
    <div>
      <h3 className="fs-5 fw-bold mb-4">Top Categories</h3>
      <Row>
        {items.map((x) => (
          <Col className="text-center">
            <div className="home-category small-category rounded-8 mx-auto mb-3">
              <img src={Blob_Base_Url + "/images/category/" + x.img} />
            </div>
            <p className="fw-bold font-md text-center">{x.title}</p>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export const LargeCategory = () => {
  const items = [
    {
      img: "computer.jpeg",
    },
    {
      img: "electronics.jpeg",
    },
    {
      img: "furniture.jpeg",
    },
    {
      img: "kitchen.jpeg",
    },
  ];

  return (
    <div>
      <h1 className="text-dark fs-4 fw-bold">Let's Explore</h1>
      <Row>
        {items.map((x) => (
          <Col md="6 mb-4">
            <div className="home-category rounded-8">
              <img src={Blob_Base_Url + "/images/" + x.img} />
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};
